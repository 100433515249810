@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&family=Roboto&display=swap');

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    /*background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(233, 255, 255, 1) 0%, rgba(218, 245, 255, 1) 49%, rgba(185, 228, 255, 1) 100%);*/
}


*::selection {
    background-color: #4CAF50; /* Зелений фон */
    color: white; /* Білий текст */
}

.header {
    background-color: transparent !important;
    transition: background-color 0.5s !important;
    /* інші стилі для хедера */
}

.header.scrolled {
    background-color: #000 !important;
}


.ourCompanyBackground, .ourMissionBackground, .aboutUsBackground, .ourCompanyBackgroundMobile, .ourTeamBackground {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
}
.ourCompanyBackgroundMobile {
    background-image: url("./assets/ourCompany/about-company-mobile.png");
    display: none;
    @media screen and (max-width: 600px) {
        display: block;
    }
}
.ourTeamBackground {
    background-image: url("./assets/ourCompany/ourTeam.png");
}

.aboutUsBackground {
    background-image: url("./assets/ourCompany/about-us.png");
}
.ourCompanyBackground {
    background-image: url("./assets/ourCompany/about-company.png");
    @media screen and (max-width: 600px) {
        display: none;
    }
}

.ourMissionBackground {
    background-image: url("./assets/ourCompany/our-mission.png");
}

.header-fill {
    background-color: black !important;
}
.link {
    text-decoration: none;
}
.blur {
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(8px);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    top: 0;
  }

.textBackgroundBlock {
    position: absolute;
    top: -5%;
    left: -40px;
    width: 60%;
    height: 105%;
    background-color: rgb(113, 181, 211, 0.5);
    z-index: -1;
}

.textBackgroundBlock-right {
    position: absolute;
    top: -5%;
    right: -40px;
    width: 60%;
    height: 105%;
    background-color:rgb(84, 142, 50, 0.5);
    z-index: -1;
}
@media screen and (max-width: 900px) {
    .textBackgroundBlock, .textBackgroundBlock-right {
       display: none;
    }
}
.footer {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(212,212,212,1) 0%, rgba(5,12,42,1) 0%, rgba(43,112,185,1) 100%, rgba(138,181,251,1) 100%);
    color: white;
}
.overlay {
    position: absolute; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3); /* Black background with opacity */
    z-index: 10; /* Specify a stack order in case you're using a different order for other elements */
}

.img-accordion {
    width: 100%;
    height: 500px;
    display: flex;
    margin: 0 auto;
    padding-top: 20px;
}
@media screen and (max-width: 900px) {
    .img-accordion {

     }
    }

.accordionItem {
    flex: 1;
    height: 100%;
    cursor: pointer;
    margin: 0 2px;
    transition: all 1s;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    border-radius: 4px;
}

.accordionItem1 {
    background-image: url("./assets/productionProcess/img1.png")
}

.accordionItem2 {
    background-image: url("./assets/productionProcess/img2.png")
}

.accordionItem3 {
    background-image: url("./assets/productionProcess/img3.png")
}

.accordionItem4 {
    background-image: url("./assets/productionProcess/img4.png")
}

.accordionItem5 {
    background-image: url("https://cdn.marvel.com/content/1x/msm2_gameplayreveal_kraven_4k_legal_2023.jpg");
}

.accordionItem p {
    opacity: 0;
    transition: all 0.3s linear;
}

.accordionItem:hover p {
    opacity: 1;
}


.accordionItem:hover {
    flex: 5;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
